<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      api-url="/invoice-history"
      api-download-url="/invoice-history-export"
      download-file-name="danh-sach-hoa-don.xlsx"
      table-height="calc(100vh - 196px)"
      @downloadFile="downloadFile"
    ></custom-table>
  </div>
</template>

<script>
import {
  INVOICE_TAX_STATUS,
  INVOICE_CLASSIFY,
  CUSTOM_VIEW_BILL_HISTORY,
  INVOICE_TYPE_PRODUCT_ORDER,
  INVOICE_TYPE_CREATE_EXPORT,
  ORDER_SOURCES,
  BILL_TYPE_COMPANY,
  YES_NO_OPTIONS,
} from "@/libs/const";
import moment from "moment";

export default {
  name: "History",
  data: () => ({
    columns: [],
    customView: CUSTOM_VIEW_BILL_HISTORY,
    taxStatusOptions: [...INVOICE_TAX_STATUS],
    typeOptions: [...INVOICE_CLASSIFY],
    sourceOptions: [...ORDER_SOURCES],
    poOptions: [...INVOICE_TYPE_PRODUCT_ORDER],
    ceOptions: [...INVOICE_TYPE_CREATE_EXPORT],
    billOptions: [...BILL_TYPE_COMPANY],
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.method"),
        placeholder: this.$t("labels.method"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: this.typeOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.export_type"),
        placeholder: this.$t("labels.export_type"),
        name: "type_product_order",
        hasSort: false,
        sortName: "type_product_order",
        key: "type_product_order",
        options: this.poOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.income"),
        placeholder: this.$t("labels.income"),
        name: "is_income",
        hasSort: false,
        sortName: "is_income",
        key: "is_income",
        options: this.yesNoOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.invoice_no"),
        placeholder: this.$t("labels.invoice_no"),
        name: "invoice_no",
        hasSort: false,
        sortName: "invoice_no",
        key: "invoice_no",
        required: true,
        component: "InvoiceTracking",
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "tax_status",
        hasSort: false,
        sortName: "tax_status",
        key: "tax_status",
        options: this.taxStatusOptions,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.date"),
        placeholder: this.$t("labels.date"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
        defaultValue: [
          moment().subtract("1", "day").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      /* {
        type: "select-employee",
        label: this.$t("labels.exported_identity"),
        placeholder: this.$t("labels.exported_identity"),
        name: "id_identity",
        hasSort: false,
        sortName: "id_identity",
        key: "identity_name",
        required: true,
      }, */
      {
        type: "select-filter",
        label: this.$t("labels.order_source"),
        placeholder: this.$t("labels.order_source"),
        name: "source",
        hasSort: false,
        sortName: "source",
        key: "source",
        options: this.sourceOptions,
        required: true,
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_customer_e_marketplace_shop",
        hasSort: false,
        sortName: "customer_e_marketplace_shop_name",
        key: "customer_e_marketplace_shop_name",
        required: true,
        params: {
          "is-check-e-market": false,
          "is-show-publish-invoice-only": true,
        },
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_ids",
        hasSort: false,
        sortName: "customer_order_ids",
        key: "customer_order_ids",
        required: true,
        component: "InvoiceOrder",
      },
      /* {
        type: "date-range-filter",
        label: this.$t("labels.from"),
        placeholder: this.$t("labels.from"),
        name: "data_from",
        hasSort: false,
        sortName: "data_from",
        key: "data_from",
        required: true,
        dateOnly: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.to"),
        placeholder: this.$t("labels.to"),
        name: "data_to",
        hasSort: false,
        sortName: "data_to",
        key: "data_to",
        required: true,
        dateOnly: true,
      }, */
      /* {
        type: "select-filter",
        label: this.$t("labels.option_2"),
        placeholder: this.$t("labels.option_2"),
        name: "type_create_export",
        hasSort: false,
        sortName: "type_create_export",
        key: "type_create_export",
        options: this.ceOptions,
        required: true,
      }, */
      {
        type: "input-filter-from-to",
        label: this.$t("labels.total_revenue"),
        placeholder: this.$t("labels.total_revenue"),
        name: "total_revenue",
        hasSort: false,
        sortName: "total_revenue",
        key: "total_revenue",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.ssc_fee"),
        placeholder: this.$t("labels.ssc_fee"),
        name: "ssc_fee",
        hasSort: false,
        sortName: "ssc_fee",
        key: "ssc_fee",
        required: true,
      },
      /* {
        type: "button",
        label: "",
        headerAction: "",
        headerActionColor: "",
        headerActionLabel: "",
        action: false,
        required: true,
        buttonActions: [
          {
            action: "downloadFile",
            text: this.$t("labels.excel"),
            color: "warning",
            class: "",
            permissions: [],
          },
        ],
      }, */
    ];
    this.columns = [...columns];
  },
  methods: {
    downloadFile(item) {
      const filenameSplit = item.file.split("/");
      const filename = filenameSplit[filenameSplit.length - 1];
      this.downloadExcelFile(
        "/download-export-excel-file",
        { filename: item.file },
        filename
      );
    },
  },
};
</script>
